<template>
  <div class="account-management">
    <el-row>
      <el-col 
        :span="4"
        class="sideBar-container"
      >
        <SettingMenu />
      </el-col>
      <el-col
        :md="{span:24}"
        :lg="{span:20}"
        class="setting-container"
      >
        <div class="setting-hamberg">
          <el-button 
            class="btn-hamburger"
            type="text"
            icon="el-icon-s-fold"
            circle
            plain
            size="medium"
            style="color: #606266;" 
            @click="drawer = true"
          />
          <el-drawer
            title="運動數據公益平台"
            :visible.sync="drawer"
            :direction="direction"
            size="80%"
          >
            <SettingMenu />
          </el-drawer>
        </div>
        <router-view class="setting-main" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { MemberRole } from '@/Mixins'
import SettingMenu from './components/SettingMenu.vue'

export default {
  name: 'AccountManagement',
  components: { SettingMenu },
  mixins: [MemberRole],
  data () {
    return {
      drawer: false,
      direction: 'btt'
    }
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.closeDrawer()
      next()
    })
  },
  methods: {
    closeDrawer() {
      this.drawer = false
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.account-management{
  margin: 0 auto;

  .setting-container{
    position: relative;

    .setting-hamberg{
      display: none;
    }
  }

  @media screen and (max-width:1200px) { 
    .sideBar-container{
      display: none;
    }

    .setting-container{
      .setting-hamberg{
        display: block;
        .btn-hamburger{
          width: 100%;
          text-align: left;
        }

        .el-button:hover{
          transition: 0.25s;
          border: none;
          background-color: transparent;
        }

        .el-button:focus{
          border: none;
          background-color: transparent;
        }
      }
    }
    
  }
}  
</style>
