<template>
  <div class="setting-menu">
    <el-menu
      default-active="1"
      background-color="#f3f2f1"
    >
      <el-menu-item>
        <router-link active-class="active" :to="{ name: 'BasicInfo' }">
          {{ $t(`accountManagement.sidebarBasic`) }}
        </router-link>
      </el-menu-item>
      <el-menu-item>
        <router-link active-class="active" :to="{ name: 'ChangePwd' }">
          {{ $t(`accountManagement.sidebarPassword`) }}
        </router-link>
      </el-menu-item>
      <el-menu-item v-if="hideForSubadminOrConsumer">
        <router-link active-class="active" :to="{ name: 'GetToken' }">
          {{ $t(`accountManagement.sidebarToken`) }}
        </router-link>
      </el-menu-item>
      <el-menu-item v-if="hideForConsumer">
        <router-link active-class="active" :to="{ name: 'AccountManagement' }">
          {{ $t(`accountManagement.sidebarAccount`) }}
        </router-link>
      </el-menu-item>
      <el-menu-item v-if="showForOrgOrConsumer">
        <router-link active-class="active" :to="{ name: 'OrgManagement' }">
          {{ $t(`accountManagement.sidebarOrganization`) }}
        </router-link>
      </el-menu-item>
      <el-menu-item v-if="showForAdmin">
        <router-link active-class="active" :to="{ name: 'ConsumerReview' }">
          {{ $t(`accountManagement.sidebarConsumer`) }}
        </router-link>
      </el-menu-item>
      <el-menu-item v-if="showForAdmin">
        <router-link active-class="active" :to="{ name: 'DeviceManagement' }">
          {{ $t(`accountManagement.sidebarDistribute`) }}
        </router-link>
      </el-menu-item>
      <!-- test -->
      <!-- <template v-for="(menuItem, index) in subMenuItems">
        <el-menu-item v-if="shouldShowMenuItem(menuItem)" :key="index">
          <router-link active-class="active" :to="{ name: menuItem.routeName }">
            {{ $t(`accountManagement.${menuItem.displayName}`) }}
          </router-link>
        </el-menu-item>
      </template> -->
    </el-menu>
  </div>
  
</template>

<script>
import { MemberRole } from '@/Mixins'

export default {
  name: 'SettingMenu',
  mixins: [MemberRole],
  data() {
    return {
      subMenuItems: [
        { 
          displayName: 'sidebarBasic',
          routeName: 'BasicInfo',
          condition: true
        },
        {
          displayName: 'sidebarPassword',
          routeName: 'ChangePwd',
          condition: true
        },
        {
          displayName: 'sidebarToken',
          routeName: 'GetToken',
          condition: !(this.isSubadmin || this.isConsumer)
        },
        {
          displayName: 'sidebarAccount',
          routeName: 'AccountManagement',
          condition: !this.isConsumer
        },
        {
          displayName: 'sidebarOrganization',
          routeName: 'OrgManagement',
          condition: this.isAdminTeam
        },
        {
          displayName: 'sidebarConsumer',
          routeName: 'ConsumerReview',
          condition: this.isAdmin
        },
        {
          displayName: 'sidebarDistribute',
          routeName: 'DeviceManagement',
          condition: this.showForAdmin
        }
      ]
    }
  },
  computed: {
    hideForSubadminOrConsumer () {
      return !(this.isSubadmin || this.isConsumer)
    },
    hideForConsumer () {
      return !this.isConsumer
    },
    showForOrgOrConsumer () {
      return !(this.isOrgTeam || this.isConsumer)
    },
    showForAdmin () {
      return this.isAdmin
    }
  },
  methods: {
    shouldShowMenuItem(menuItem) {
      console.log('hi-----', this.isSubadmin || this.isConsumer)
      console.log(!(this.isSubadmin || this.isConsumer))
      console.log(menuItem.routeName, menuItem.condition)
      return menuItem.condition
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.setting-menu{
  border-right: 2px solid #c6bdc2;
    text-align: left;

    .el-menu-item{
      font-size: 16px;
      padding-left: 10px !important;

      a{
        display: inline-block;
        width: 150px;
      }
    }

    .active {
      color: $theme;
    }    .el-menu-item.is-active {
      color:$text_default;
    }
    
}
</style>
